<template>
  <div class="pictorial_2">
    <div class="left_content">
      <div class="text_content">
        <div class="text_title">
          <div class="text_word">直播热聊 找寻心动的声音</div>
        </div>
        <div class="explain">体验多人在线语音群聊</div>
        <div class="intros">随刻线上陪伴，温暖每个日夜</div>
        <div class="down_btn">
          <BtnDownload />
        </div>
      </div>
    </div>
    <div class="right_content">
      <div class="iphone_content">
        <div class="iphone_icon">
          <img class="index_img" src="../assets/images/index_chat.png" alt="" />
          <img class="icon_text" src="../assets/images/icon_text.png" alt="" />
          <img
            class="icon_text1"
            src="../assets/images/icon_text1.png"
            alt=""
          />
          <img
            class="icon_text2"
            src="../assets/images/icon_text2.png"
            alt=""
          />
          <img
            class="icon_text3"
            src="../assets/images/icon_text3.png"
            alt=""
          />
          <img
            class="icon_text4"
            src="../assets/images/icon_text4.png"
            alt=""
          />
          <img
            class="icon_text5"
            src="../assets/images/icon_text5.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EarthSphere from "@/components/EarthSphere.vue";
import BtnDownload from "@/components/BtnDownload.vue";
import Swiper from "swiper";

export default {
  name: "",
  components: {
    EarthSphere,
    BtnDownload,
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 30,
        // setWrapperSize: true,
        mousewheel: true,
        // autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    img: {
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(".swiper-container", {
            loop: true,

            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },

            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
          console.log(mySwiper);
        });
      },
    },
  },
  created() {},
  mounted() {
    // 监听页面滚动事件

    window.addEventListener("scroll", this.scrolling, true);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.pictorial_2 {
  width: 100%;

  //   width: 1920px;
  height: 931px;
  display: flex;
  overflow: hidden;
  .left_content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .text_content {
      width: 590px;
      height: 422px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .text_title {
        width: 590px;
        height: 72px;

        .text_word {
          height: 69px;
          width: 590px;
          color: rgb(52, 53, 55);
          font-family: 微软雅黑;
          font-size: 52px;
          font-weight: 700;
          line-height: 70px;
          text-align: center;
          background: url("../assets/images/bottom_color.png") no-repeat;
          background-position: bottom;
        }
      }

      .down_btn {
        width: 100%;
        height: 100%;
      }
      .explain {
        width: 400px;
        height: 52px;
        color: rgb(52, 53, 55);
        font-family: 微软雅黑;
        font-size: 40px;
        font-weight: 400;
        line-height: 53px;
        letter-spacing: 0px;
        text-align: left;
        margin: 20px 0 16px 0;
      }
      .intros {
        width: 364px;
        height: 37px;
        color: rgb(151, 151, 151);
        font-family: 微软雅黑;
        font-size: 28px;
        font-weight: 400;
        line-height: 37px;
        letter-spacing: 0px;
        text-align: left;
        margin: 10px 0 30px 0;
      }
    }
  }
  .right_content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: rgb(247, 247, 247);
    .iphone_content {
      width: 400px;
      height: 82%;
      // height: 705px;
      position: relative;

      .iphone_icon {
        width: 100%;
        display: flex;
        // margin-top: 54px;
        justify-content: center;
        align-items: center;
        z-index: 100;

        .index_img {
          width: 400px;
          margin: -125px;
        }
        .icon_text {
          width: 120px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 196px;
          left: -120px;
          // left: -210px;
          background: rgba(52, 53, 55, 0.7);
        }
        .icon_text1 {
          width: 146px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 368px;
          left: -80px;
          background: rgba(52, 53, 55, 0.7);
        }
        .icon_text2 {
          width: 202px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 198px;
          left: 305px;
          background: rgba(52, 53, 55, 0.7);
        }
        .icon_text3 {
          width: 105px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 322px;
          left: 395px;
          background: rgba(52, 53, 55, 0.7);
        }
        .icon_text4 {
          width: 204px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 453px;
          left: 316px;
          background: rgba(52, 53, 55, 0.7);
        }
        .icon_text5 {
          width: 132px;
          height: 40px;
          position: absolute;
          border-radius: 30px;
          top: 558px;
          left: 424px;
          background: rgba(52, 53, 55, 0.7);
        }
      }
    }
  }
}
</style>
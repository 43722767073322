<!--柱状图-->
<template>
    <div class='container'>
        <div id="histogramChartDiv"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
import { autoToolTip } from "@/utils/echartsAutoTooltip.js";
export default {
    name: '',
    components: {},
    props: {
        dataVColor: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            histogramChart: "",
            //图表数据
            chartData: {
                xAxisData: ['成都线', '南充线', '宜宾线', '西昌线', '眉山线', '乐山线', '攀枝花线'],
                seriesData: [635, 880, 1165, 135, 342, 342, 524]
            }
        };
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        async createHistogramChart(histData) {
            this.chartData = histData;
            let { chartData } = this;//获取图表数据
            await (this.histogramChart && this.histogramChart.dispose());//若之前有，则销毁实例
            this.histogramChart = echarts.init(document.getElementById('histogramChartDiv'));//初始化echarts
            let option = {
                title: {
                    text: '缺陷分布',
                    top: "5%",
                    left: 'center',
                    textStyle: {
                        color: "#fff"
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                grid: {
                    left: '3%',
                    top: "20%",
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        color: "#fff",
                        interval: 0,
                        rotate: 20,
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        color: "#fff",
                    },
                    splitLine: {
                        show: true,//网格设置
                        lineStyle: {
                            color: "#70707033",
                            width: 1,
                            type: "dotted",//虚线
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        animationDuration: 3000,
                        animationEasing: "cubicInOut",
                        showBackground: true,
                        label: {
                            color: "#fff"
                        },
                        data: [],
                        color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                            {
                                offset: 1,
                                color: this.dataVColor[0]
                            },
                            {
                                offset: 0,
                                color: "#ffffff"
                            }
                        ])
                    }
                ]
            };
            option.xAxis.data = chartData.xAxisData;
            chartData.seriesData.forEach(s => {
                option.series[0].data.push(s);
            });
            await (option && this.histogramChart.setOption(option));//设置数据
            // 自动轮播
            autoToolTip(this.histogramChart, option, {
                interval: 2000,// 轮播间隔时间 默认2s
                loopSeries: false,// 是否循环轮播所有序列
                seriesIndex: 0,// 第1个被轮播的序列下标
            });
        },
    },
};
</script>
<style scoped lang='scss'>
.container {
    height: 100%;
    width: 100%;

    #histogramChartDiv {
        height: 100%;
        width: 100%;
    }
}
</style>
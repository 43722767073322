<template>
  <div class="pictorial_3">
    <div class="left_content">
      <div class="iphone_content">
        <img class="index_img" src="../assets/images/topic.png" alt="" />
        <img class="frames_1" src="../assets/images/Frame1.png" alt="" />
        <img class="frames_2" src="../assets/images/Frame2.png" alt="" />
        <img class="frames_3" src="../assets/images/Frame3.png" alt="" />
      </div>
    </div>
    <div class="right_content">
      <div class="text_content">
        <div class="text_title">
          <div class="text_word">参与有趣话题 遇见知心的TA</div>
        </div>
        <div class="explain">互动热评 说说你的看法</div>
        <div class="intros">分享美好生活，打发无聊时光</div>
        <div class="down_btn">
          <BtnDownload />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnDownload from "@/components/BtnDownload.vue";
import Swiper from "swiper";

import HistogramChart from "@/components/HistogramChart.vue";

export default {
  name: "",
  components: {
    BtnDownload,
    HistogramChart,
  },
  data() {
    return {};
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    img: {
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(".swiper-container", {
            loop: true,

            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },

            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
          console.log(mySwiper);
        });
      },
    },
  },
  created() {},
  mounted() {
    // 监听页面滚动事件

    window.addEventListener("scroll", this.scrolling, true);
  },
  methods: {},
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 768px) {
  .pictorial_3 {
    width: 100vw;
    //   width: 1920px;
    height: 931px;

    display: flex;
    overflow: hidden;
    background: linear-gradient(
      to right,
      rgb(255, 237, 219),
      rgb(255, 255, 255),
      rgb(255, 255, 255)
    );

    .left_content {
      width: 50%;
      height: 100%;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;

      .iphone_content {
        // width: 400px;
        width: 50%;

        position: relative;

        .index_img {
          height: 100%;
        }
        .frames_1 {
          width: 164px;
          top: 534px;
          left: -134px;
          position: absolute;
        }
        .frames_2 {
          width: 164px;
          top: 206px;
          left: -12px;
          left: 356px;
          position: absolute;
        }
        .frames_3 {
          width: 164px;
          top: 638px;
          position: absolute;
        }
      }
    }

    .right_content {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      background: white;
      .text_content {
        width: 660px;
        height: 550px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .text_title {
          width: 660px;
          height: 70px;
          .text_word {
            color: rgb(52, 53, 55);
            font-family: 微软雅黑;
            font-size: 52px;
            font-weight: 700;
            line-height: 69px;
            text-align: center;
            background: url("../assets/images/bottom_color1.png") no-repeat;
            background-position: bottom;
          }
        }
        .explain {
          width: 100%;
          height: 52px;
          margin: 24px 0 36px 0;
          color: rgb(52, 53, 55);
          font-family: 微软雅黑;
          font-size: 40px;
          font-weight: 400;
          line-height: 53px;
        }
        .intros {
          width: 100%;
          height: 37px;
          color: rgb(151, 151, 151);
          font-family: 微软雅黑;
          font-size: 28px;
          font-weight: 400;
          line-height: 37px;
          letter-spacing: 0px;
          text-align: left;
          margin: 10px 0 30px 0;
        }
      }
    }
  }
}
</style>
<template>
  <div class="pictorial_1">
    <div class="middle_part">
      <div class="text_title">技术流大神, &nbsp;默契队友带你Carry全场</div>
      <div class="text_explain">圈圈开黑 与快乐组队 欢笑不落单</div>
      <div class="text_explain">以兴趣速配，让缘分相遇</div>

      <div class="down_btn">
        <el-button class="ios_dow" icon="iso_download" type="primary" round
          ><span class="down_text">AppStore 下载</span></el-button
        >
        <el-button class="android_dow" icon="and_download" type="default" round
          ><span class="down_text">Android 下载</span></el-button
        >
      </div>
    </div>

    <div class="bottom_legend">
      <div class="iphone_icon">
        <img class="index_img" src="../assets/images/index.png" alt="" />
      </div>

      <div class="bom_icon">
        <div class="left_icon">
          <img class="icon_game" src="../assets/images/icon_1.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_2.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_3.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_4.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_5.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_8.png" alt="" />
        </div>

        <div class="right_icon">
          <img class="icon_game" src="../assets/images/icon_6.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_7.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_1.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_8.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_4.png" alt="" />
          <img class="icon_game" src="../assets/images/icon_2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted } from "vue";
import EarthSphere from "@/components/EarthSphere.vue";
import BtnDownload from "@/components/BtnDownload.vue";
import Swiper from "swiper";

import HistogramChart from "@/components/HistogramChart.vue";
import HomePageOne from "@/components/HomePageOne.vue";
import HomePageTwo from "@/components/HomePageTwo.vue";
import HomePageThree from "@/components/HomePageThree.vue";

export default {
  name: "",
  components: {
    EarthSphere,
    BtnDownload,
    HistogramChart,
    HomePageOne,
    HomePageTwo,
    HomePageThree,
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 30,
        // setWrapperSize: true,
        mousewheel: true,
        // autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    img: {
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(".swiper-container", {
            loop: true,

            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },

            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
          console.log(mySwiper);
        });
      },
    },
  },
  created() {},
  mounted() {
    // 监听页面滚动事件

    window.addEventListener("scroll", this.scrolling, true);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        console.log("header 滚动条向上滚动了！");
      } else {
        console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        console.log("header 到了最顶部");
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
<style>
.iso_download {
  width: 24px;
  height: 28px;
  display: flex;
  margin: 2px 0px 0 4px;
  background: url("../assets/images/ios.png") center no-repeat;

  background-size: cover;
}
.and_download {
  width: 24px;
  height: 28px;
  display: flex;
  margin: 4px 0px 0 4px;
  background: url("../assets/images/android.png") center no-repeat;

  background-size: cover;
}

/* .iso_download {
  content: "替";
  font-size: 16px;
  visibility: hidden;
} */
</style>
<style lang="scss" scoped>
.el-button--primary {
  //需要更改的按钮类型 type='primary'
  background: rgb(49, 49, 49) !important;
  border-color: rgb(49, 49, 49) !important;
}
//移入时按钮样式 type='primary'
.el-button--primary:hover {
  // background: #fff !important;
  // border-color: #fff !important;
  color: #fff !important;
  opacity: 0.8;
}
.el-button--default {
  //需要更改的按钮类型 type='default'
  color: #fff !important;
  background: rgb(255, 133, 14) !important;
  border-color: rgb(255, 133, 14) !important;
}
//移入时按钮样式type='default'
.el-button--default:hover {
  color: #fff !important;
  opacity: 0.8;
}

@media screen and (min-width: 100px) {
  .pictorial_1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: linear-gradient(
      to right,
      rgb(255, 237, 219),
      rgb(255, 255, 255),
      rgb(255, 255, 255)
    );

    .middle_part {
      width: 100%;
      height: 60%;
      display: flex;

      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text_title {
        width: 300px;
        height: 28px;
        color: rgb(52, 53, 55);
        font-family: 微软雅黑;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
      }
      .text_explain {
        width: 705px;
        height: 64px;
        color: rgb(52, 53, 55);
        font-family: 微软雅黑;
        font-size: 48px;
        font-weight: 700;
        line-height: 64px;
        letter-spacing: 0px;
        text-align: center;
        margin: 8px 0;
      }

      .down_btn {
        width: 500px;
        height: 65px;
        display: flex;
        align-items: center;
        margin-top: 16px;
        z-index: 1000;

        .el-button {
          margin: 50px;
          margin-left: 0;
        }

        .ios_dow {
          width: 231px;
          height: 58px;
          margin: 0 14px;

          border-radius: 60px;
          .down_text {
            color: rgb(255, 255, 255);
            font-family: 微软雅黑;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: center;
            position: relative;
            top: -24px;
            left: 18px;
          }
        }
        .android_dow {
          width: 231px;
          height: 58px;
          margin: 0 18px;

          border-radius: 60px;
          .down_text {
            color: rgb(255, 255, 255);
            font-family: 微软雅黑;
            font-size: 18px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0px;
            text-align: center;
            position: relative;
            top: -24px;
            left: 13px;
          }
        }
      }
    }
    .bottom_legend {
      width: 100%;
      height: 40%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;

      .iphone_icon {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;

        .index_img {
          width: 560px;
          height: 940px;
          top: -60px;
          position: absolute;
        }
      }

      .bom_icon {
        width: 100%;
        height: 128px;
        display: flex;
        margin-top: 2px;
        align-items: center;
        z-index: 10;
        justify-content: space-between;

        .left_icon {
          width: 40%;
          height: 128px;
          display: flex;
          overflow: hidden;
          justify-content: flex-start;
          .icon_game {
            width: 126px;
            height: 126px;
            opacity: 0.5;
          }
        }
        .right_icon {
          width: 40%;
          height: 128px;
          display: flex;
          overflow: hidden;
          justify-content: flex-end;
          .icon_game {
            width: 126px;
            height: 126px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="down_btn">
    <div class="downs">
      <el-button class="ios_dow" icon="iso_download" type="primary" round
        ><span
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          class="down_text"
          >AppStore 下载</span
        ></el-button
      >
      <el-button class="android_dow" icon="and_download" type="default" round
        ><span
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          class="down_text"
          >Android 下载</span
        ></el-button
      >
    </div>
    <div class="down_imgs" v-show="down">
      <div class="arrow"></div>
      <img class="imgs" src="../assets//images/down_imgs.png" alt="" />
      <span class="texts">扫码下载应用</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: {
    // dataVColor: {
    //   type: Array,
    //   default: [],
    // },
  },
  data() {
    return {
      down: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleMouseEnter() {
      this.down = true;
      console.log("鼠标移入");
    },
    handleMouseLeave() {
      this.down = false;

      console.log("鼠标移出");
    },
  },
};
</script>
<style>
.iso_download {
  width: 22px;
  height: 24px;
  display: flex;
  margin: 2px 2px 0 5px;
  background: url("../assets/images/ios.png") center no-repeat;

  background-size: cover;
}
.and_download {
  width: 22px;
  height: 24px;
  display: flex;
  margin: 2px 4px 0 5px;
  background: url("../assets/images/android.png") center no-repeat;

  background-size: cover;
}
</style>
<style scoped lang='scss'>
//显示时按钮样式
.el-button--primary {
  //需要更改的按钮类型 type='primary'
  background: rgb(49, 49, 49) !important;
  border-color: rgb(49, 49, 49) !important;
}
//移入时按钮样式 type='primary'
.el-button--primary:hover {
  // background: #fff !important;
  // border-color: #fff !important;
  color: #fff !important;
  opacity: 0.8;
}
.el-button--default {
  //需要更改的按钮类型 type='default'
  color: #fff !important;
  background: rgb(255, 133, 14) !important;
  border-color: rgb(255, 133, 14) !important;
}
//移入时按钮样式type='default'
.el-button--default:hover {
  color: #fff !important;
  opacity: 0.8;
}

.down_btn {
  // width: 231px;
  width: 100%;
  height: 152px;
  display: flex;

  .downs {
    // width: 100%;
    height: 152px;
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    .ios_dow,
    .android_dow {
      width: 202px;
      height: 56px;
      margin: 12px 0;
      border-radius: 60px;
      .down_text {
        color: rgb(255, 255, 255);
        font-family: 微软雅黑;
        font-size: 18px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
        position: relative;
        top: -25px;
        left: 19px;
      }
    }
    .el-button {
      margin-left: 0;
    }
  }
  .down_imgs {
    width: 266px;
    height: 290px;
    display: flex;
    flex-direction: column;
    margin-left: 36px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    background: rgb(255, 255, 255);

    .arrow {
      width: 40px;
      height: 40px;
      z-index: 1000;
      position: absolute;
      top: 27px;
      left: -40px;
      border: 20px solid transparent;
      border-right: 20px solid rgba(0, 0, 0, 0.15);
      // background: rgb(196, 196, 196);
      // border: 1px solid goldenrod;
    }
    .imgs {
      width: 212px;
      height: 212px;
      margin: 24px;
    }
    .texts {
      width: 100%;
      height: 20px;
      color: rgb(52, 53, 55);
      font-family: 微软雅黑;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
  }
}
</style>

<template>
  <div id="app">
    <swiper :options="swiperOption" ref="mySwiper">
      <!-- slides -->
      <swiper-slide><HomePageOne /></swiper-slide>
      <swiper-slide><HomePageTwo /></swiper-slide>
      <swiper-slide><HomePageThree /></swiper-slide>
      <swiper-slide><HomePageFour /></swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import { onMounted } from "vue";
import EarthSphere from "@/components/EarthSphere.vue";
import BtnDownload from "@/components/BtnDownload.vue";
import Swiper from "swiper";

import HistogramChart from "@/components/HistogramChart.vue";
import HomePageOne from "@/components/HomePageOne.vue";
import HomePageTwo from "@/components/HomePageTwo.vue";
import HomePageThree from "@/components/HomePageThree.vue";
import HomePageFour from "@/components/HomePageFour.vue";
export default {
  name: "",
  components: {
    EarthSphere,
    BtnDownload,
    HistogramChart,
    HomePageFour,
    HomePageOne,
    HomePageTwo,
    HomePageThree,
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        slidesPerView: 1,
        spaceBetween: 30,
        // setWrapperSize: true,
        mousewheel: true,
        // autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // ...
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  watch: {
    img: {
      handler() {
        this.$nextTick(() => {
          var mySwiper = new Swiper(".swiper-container", {
            loop: true,

            // 如果需要分页器
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },

            // 如果需要前进后退按钮
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
          console.log(mySwiper);
        });
      },
    },
  },
  created() {
    console.log(this.isMobile())
    if (this.isMobile()) {
      console.log(this.isMobile(),123)
      location.href = 'https://h5-quanquan.ningmengxinxi.cn/'
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling, true);
  },
  methods: {
    isMobile() {
	    let flag = navigator.userAgent.match(
	        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	    );
      // console.log(flag)
	    return flag;
	  },

    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        console.log("header 滚动条向上滚动了！");
      } else {
        console.log("header  滚动条向下滚动了！");
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        console.log("header 到了最顶部");
      }
    },
  },
};
</script>
<style>
.iso_download {
  width: 24px;
  height: 28px;
  display: flex;
  margin: 2px 0px 0 4px;
  background: url("../assets/images/ios.png") center no-repeat;

  background-size: cover;
}
.and_download {
  width: 24px;
  height: 28px;
  display: flex;
  margin: 4px 0px 0 4px;
  background: url("../assets/images/android.png") center no-repeat;

  background-size: cover;
}
</style>
<style scoped lang='scss'>
</style>